import React, { useState,useEffect } from 'react';
import './StarRating.css'; // Import the CSS file for styling
import axios from "axios";
import { useTranslation } from './TranslationContext';


const Addcomment = () => {
  const [rating, setRating] = useState(0); // State to store the current rating
  const [comment, setComment] = useState(''); // State to store the comment
  const [setcards, setSetcards] = useState([]);
  const { translate } = useTranslation();

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating); // Update the rating state when a star is clicked
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value); // Update the comment state when the input changes
  };

  const submitbutton = async () => {
    // console.log('Selected Star Count:', rating);
    // console.log('Input Box Value:', comment);
    const review = comment;

     const  api_url = localStorage.getItem('api_url');
     const product_id = localStorage.getItem('product_id');
     const user_id = localStorage.getItem('admin_id');

     const response1 = await axios.post(api_url + '/productReview', {
           user_id,
          product_id,
          review,
          rating
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [(data, headers) => {
          const params = new URLSearchParams();
          for (const key in data) {
            params.append(key, data[key]);
          }
          return params.toString();
        }],
      });

      const check_res2 = response1.data.status;

       if(check_res2){
        alert("user review added successfully");
        window.location.reload();
       }


  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
       
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);


  return (
    <div>
      <div style={{ display: 'flex' }}>
        <p className='add_co'>{translate('addcomment')}</p>
        <div className="star-rating">
          {[1, 2, 3, 4, 5].map((star) => (
            <span key={star} className={star <= rating ? 'star selected' : 'star'} onClick={() => handleStarClick(star)}>&#9733;</span>
          ))}
        </div>
      </div>
      <div>
        <input type="text" className='comm_input' value={comment} onChange={handleCommentChange} />
      </div>
      <br />
      <button type="button" className='submit_button' onClick={submitbutton}> {translate('submit')} </button>
    </div>
  );
};

export default Addcomment;
