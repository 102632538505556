import React, { useState, useEffect } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import axios from 'axios';
import Prductslide1 from './Prductslide1';
import { withRouter } from './withRouter';
import { useTranslation } from './TranslationContext';

function Trackorder(props) {
  const [apiUrl] = useState(localStorage.getItem('api_url'));
  const [orderId] = useState(localStorage.getItem('trans_order_id'));
  const [cartId] = useState(localStorage.getItem('trans_cart_id'));
  const [orderDetails, setOrderDetails] = useState('');
  const [cartDetails, setCartDetails] = useState({});
  const [updatedDate, setUpdatedDate] = useState('');
  const { translate } = useTranslation(); // Use translation hook

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const orderDetailApi = () => {
    axios.get(`${apiUrl}/trackorders/${orderId}`).then((res) => {
      const data = res.data;
      setOrderDetails(data.trackorder_details[0].status);
      setUpdatedDate(formatDate(data.trackorder_details[0].updateddate));

      axios.get(`${apiUrl}/myOrder_cart/${cartId}`).then((res) => {
        const data1 = res.data;
        setCartDetails(data1.cartItems[0]);
      });
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    orderDetailApi();
  }, []); // Empty dependency array ensures this runs once

  const backtoOrders = () => {
    props.navigate('/Myorders');
  };

  return (
    <div className="box">
      <Header_home />
      <div className="trackorder1">
        <div className="track_flex">
          <div className="track_detail_order">
            <div className="track_img">
              <img
                src={cartDetails.product_image}
                alt={cartDetails.product_name}
                className="or_img_tr"
              />
            </div>
            <div className="oder_track_detail">
              <p className="pro_track">{cartDetails.product_name}</p>
              <p className="pro_track1">{cartDetails.brand_name}</p>
              <p className="product-price">
                {cartDetails.ori_price < cartDetails.price ? (
                  <>
                    <span className="original-price">
                      ₹{cartDetails.price}
                    </span>
                    <span className="final-price">
                      ₹{cartDetails.ori_price}
                    </span>
                  </>
                ) : (
                  <span className="final-price">₹{cartDetails.price}</span>
                )}
              </p>
            </div>
          </div>
          <div className="track_no">
            <h3>
              <b>
                {orderDetails} On {updatedDate}
              </b>
            </h3>
            <p>{translate('please_mark_received')}</p>
            <p>
              {translate('tracking_number')}:{' '}
              <b style={{ color: '#7C3A8E' }}>{orderId}</b>
            </p>
            {orderDetails !== 'delivered' && cartDetails.store_mobile && (
              <p>
                {translate('store_number')}: <b>{cartDetails.store_mobile}</b>
              </p>
            )}
            {orderDetails !== 'delivered' &&
              cartDetails.agent_mobile && (
                <p>
                  {translate('agent_number')}: <b>{cartDetails.agent_mobile}</b>
                </p>
              )}
          </div>
        </div>
        <hr />
        <div className="order-status" style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '50px', marginLeft: '100px' }}>
            <div
              className={`status ${
                orderDetails === 'order_placed' || orderDetails === 'purchased'
                  ? 'active'
                  : ''
              }`}
            ></div>
            <p className="pl_de"><b>{translate('order_placed')}</b></p>
          </div>
          <hr className="status-line" />
          <div style={{ width: '65px' }}>
            <div
              className={`status ${orderDetails === 'dispatched' ? 'active' : ''}`}
            ></div>
            <p className="pl_de"><b>{translate('shipped')}</b></p>
          </div>
          <hr className="status-line" />
          <div style={{ marginRight: '75px' }}>
            <div
              className={`status ${orderDetails === 'delivered' ? 'active' : ''}`}
            ></div>
            <p className="pl_de"><b>{translate('delivered')}</b></p>
          </div>
        </div>
        <div className="backtoorders">
          <button className="order_button" onClick={backtoOrders}>
            {translate('back_to_orders')}
          </button>
        </div>
        <div className="sell_best_product">
          <h2>{translate('similar_products')}</h2>
          <Prductslide1 />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(Trackorder);
