import React, { useState, useEffect } from "react";
import './Productuser_review.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { withRouter } from "./withRouter";
import Product_rating from "./Product_rating";
import Addcomment from "./Addcomment";
import { useTranslation } from './TranslationContext';


const Productuser_review = () => {
    const navigate = useNavigate();

    const [expandedMenu, setExpandedMenu] = useState(null);
    const [cards, setCards] = useState([]);
    const { translate } = useTranslation();


    const handleMenuClick = (menuId) => {
        if (expandedMenu === menuId) {
            setExpandedMenu(null);
        } else {
            setExpandedMenu(menuId);
        }
    };

    const handleDivClick = (id) => {
        console.log(id);

        localStorage.setItem("subcategory_id", id);
        navigate("/Product", { replace: true });
        window.location.reload();
    };

    useEffect(() => {
        const fetchCards = async () => {
            try {
                const product_id = localStorage.getItem('product_id');
                const api_url = localStorage.getItem('api_url');
                const response = await axios.get(api_url + '/getProductReview/' + product_id);
                setCards(response.data.review_details);
            } catch (error) {
                console.error("Error fetching card data:", error);
            }
        };

        fetchCards();
    }, []);

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div className="userreview">
            {/* add comment */}
            <div>
                <Addcomment />
            </div>
            <br />
            <div className="product_review">
                {cards.map((product, index) => (
                    <div key={index} className="review_detail">
                        <div className="reating_pro">
                            <p className="pro_fi"><b>{product.firstname}</b></p>
                            <Product_rating rating={product.rating} />
                            <p className="pro_da">{formatDate(product.createdat)}</p>
                        </div>
                        <hr></hr>
                        <p>{product.review}</p>
                    </div>
                ))}
                {cards.length === 0 && (
                    <p className="emptycart">{translate('reviewempty')}</p>
                )}
            </div>
        </div>
    );
};

export default withRouter(Productuser_review);
