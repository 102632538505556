import React, { useState, useEffect } from "react";
import './Product_sidebar.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import {withRouter} from "./withRouter";



const Product_sidebar = () => {
    const navigate = useNavigate();

  const [expandedMenu, setExpandedMenu] = useState(null);
const [setcards, setSetcards] = useState([]);


  const handleMenuClick = (menuId) => {
    if (expandedMenu === menuId) {
      setExpandedMenu(null);
    } else {
      setExpandedMenu(menuId);
    }
  };

  const handleDivClick = (id) => {
    console.log(id);

    localStorage.setItem("category_id", id);
    navigate("/Product", { replace: true });
    window.location.reload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchCards = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getCategories_menu');
        setSetcards(response.data.categories);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

  return (
    <div className="sidebar">
      <ul>

      {setcards.map((category) => (
          <li className="sidebar_manu" id={category.cat_id} onClick={() => handleMenuClick(category.cat_id)}>
            {category.cat_name} 

            {expandedMenu === category.cat_id && (
             <ul className="">
                {category.subcat.map((subcats) => (
                  <li id={subcats._id} onClick={() => handleDivClick(subcats._id)}>
                    {subcats.sub_cat_name}
                  </li>
                ))}
            </ul>
          )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default withRouter(Product_sidebar);
