import React, { useState } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Prductslide1 from './Prductslide1';
import { withRouter } from './withRouter';
import Myorderdetails from './Myorderdetails';
import { useTranslation } from './TranslationContext';

const Myorders = (props) => {
  const [orderStatus, setOrderStatus] = useState({
    order_placed: false,
    shipped: false,
    delivered: false,
  });

  const [orderTime, setOrderTime] = useState({
    last_30_days: false,
    year_2023: false,
    year_2022: false,
    year_2021: false,
    year_2020: false,
    others: false,
  });

  const { translate } = useTranslation(); // Using translation hook

  const handleOrderStatusChange = (e) => {
    const { name, checked } = e.target;
    setOrderStatus({
      order_placed: name === 'order_placed' ? checked : false,
      shipped: name === 'shipped' ? checked : false,
      delivered: name === 'delivered' ? checked : false,
    });
  };

  const handleOrderTimeChange = (e) => {
    const { name, checked } = e.target;
    setOrderTime((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  return (
    <div className="box">
      <Header_home />
      <div className='myorders'>
        <div className='fil_order'>
          <p className='my_or'><b>{translate('filters')}</b></p>
          <p></p>
          <hr />
          <p className='my_or'><b>{translate('orderstatus')}</b></p>
          <label className='or_status'>
            <input
              type="checkbox"
              name="order_placed"
              onChange={handleOrderStatusChange}
              checked={orderStatus.order_placed}
            />
            {translate('order_placed')}
          </label>
          <label className='or_status'>
            <input
              type="checkbox"
              name="shipped"
              onChange={handleOrderStatusChange}
              checked={orderStatus.shipped}
            />
            {translate('shipped')}
          </label>
          <label className='or_status'>
            <input
              type="checkbox"
              name="delivered"
              onChange={handleOrderStatusChange}
              checked={orderStatus.delivered}
            />
            {translate('delivered')}
          </label>
          
          <p></p>
          <hr />
          <p className='my_or'><b>{translate('orderstime')}</b></p>
          <label className='or_status'>
            <input
              type="checkbox"
              name="last_30_days"
              onChange={handleOrderTimeChange}
              checked={orderTime.last_30_days}
            />
            {translate('last_30_days')}
          </label>
          <label className='or_status'>
            <input
              type="checkbox"
              name="year_2024"
              onChange={handleOrderTimeChange}
              checked={orderTime.year_2024}
            />
            2024
          </label>
          {/* Add other time filters similarly */}
        </div>
        <div className='my_ord'>
          <Myorderdetails orderStatus={orderStatus} orderTime={orderTime} />
        </div>
      </div>

      <br />
      <div className='sell_best_product'>
        <h2>{translate('similar_products')}</h2>
        <Prductslide1 />
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(Myorders);
