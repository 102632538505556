import React, { useState, useEffect } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Menu from './Menu';
import axios from 'axios';
import Prductslide2 from './Prductslide2';
import Detail_tab from './Detail_tab';
import FavoriteCheckbox from './FavoriteCheckbox';
import Detail_rating from './Detail_rating';
import { useTranslation } from './TranslationContext';

const Detail_page = () => {
  const [api_url] = useState(localStorage.getItem('api_url'));
  const [product_id] = useState(localStorage.getItem('product_id'));
  const [user_id] = useState(localStorage.getItem('admin_id'));
  const [product, setProduct] = useState({});
  const [mainImage, setMainImage] = useState('');

  const { translate } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);

    const product_detailapi = () => {
      axios({
        method: 'get',
        url: `${api_url}/admingetProduct_frontend5/${product_id}/${user_id}`,
      }).then(res => {
        const data = res.data;
        setProduct(data.product);
        setMainImage(data.product.product_image);

        localStorage.setItem("coo_product_id", product_id);
        localStorage.setItem("coo_product_name", data.product.product_name);
        localStorage.setItem("coo_product_image", data.product.product_image);
        localStorage.setItem("coo_price", data.product.price);
        localStorage.setItem("coo_quantity", data.product.qty);

        const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
        const newProduct = {
          id: product_id,
          product_name: data.product.product_name,
          product_image: data.product.product_image,
          price: data.product.price,
          discountAmount:data.product.discountAmount
        };

        const updatedRecentlyViewed = recentlyViewed.filter(item => item.id !== product_id);
        updatedRecentlyViewed.unshift(newProduct);

        if (updatedRecentlyViewed.length > 5) {
          updatedRecentlyViewed.pop();
        }

        localStorage.setItem('recentlyViewed', JSON.stringify(updatedRecentlyViewed));
      });
    };

    product_detailapi();
  }, [api_url, product_id, user_id]);

  const handleImageClick = (image) => {
    setMainImage(image);
  };

  return (
    <div className="box">
      <Header_home />
      <Menu />
      <div className="short_line">
        <p className="short_text">{translate('home')} / {product.cat_name} / {product.sub_cat_name} / {product.product_name}</p>
      </div>
      <h3 className='decor_page'>{product.product_name}</h3>
      <div className='social_container'>
        <div className='rating_detail_page'>
          <div className="rating">
            <Detail_rating />
          </div>
        </div>
        <div className='share_social'>
          <img className="facebook-logo1" alt="Facebook logo" src={require('./img/facebook-logo.png')} />
          <img className="twitter1" alt="Twitter" src={require('./img/twitter.png')} />
          <img className="youtube1" alt="Youtube" src={require('./img/youtube.png')} />
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="image-gallery">
          <div className="image-thumbnails">
            {product.images && product.images.map((img, index) => (
              <img
                key={index}
                src={img.images || img.product_image}
                alt={`thumbnail-${index}`}
                onClick={() => handleImageClick(img.images || img.product_image)}
                className={mainImage === (img.images || img.product_image) ? 'active' : ''}
              />
            ))}
          </div>

          <div className="main-image">
            <img src={mainImage} alt={product.product_name} />
          </div>
        </div>
        <div className='price_amount'>
          {product.discountAmount ? (
            <>
              <p className='price_a discounted-price'>₹{product.discountAmount}</p>
              <p className='price_a original-price'><s>₹{product.price}</s></p>
            </>
          ) : (
            <p className='price_a'>₹{product.price}</p>
          )}
          <p className={`price_stock ${product.qty > 0 ? 'in_stock' : 'out_stock'}`}>
            {product.qty > 0 ? translate('in_stock') : translate('out_of_stock')}
          </p>
          {product.offer_percentage && (
            <p className='offer_percentage'>Offer: {product.offer_percentage}% off</p>
          )}
          <hr />
          <br />
          {product.c_len === 0 ? (
            product.qty > 0 && (
              <>
                <p className='price_detail1'>{translate('quantity')}</p>
                <FavoriteCheckbox />
              </>
            )
          ) : (
            <p>{translate('already_added')}</p>
          )}
          <br />
          <hr />
          <p className='price_detail1'>{product.cat_name} / {product.sub_cat_name} / {product.product_name}</p>
        </div>
      </div>
      <Detail_tab />
      <br />
      <br />
      <div className='sell_best_product'>
        <h2 className="">{translate('recently')}</h2>
        <Prductslide2 />
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default Detail_page;
